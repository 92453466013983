import React from 'react';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import styled from 'styled-components';
import { RichText } from 'prismic-reactjs';

import Image from '../Image';

import { breakpoint } from '../../theme';

const Root = styled.div`
  width: 100%;
`;

const Inner = styled.div`
  display: block;
  width: 100%;
  background-color: ${(props) => props.theme.palette.colors.grey};
  text-align: left;
`;

const Thumbnail = styled.div`
  display: block;
  width: 100%;
  background-color: ${(props) => props.theme.palette.colors.black};
`;

const Content = styled.div`
  display: block;
  padding: ${(props) => props.theme.spacing.gutter};
  width: 100%;
  color: ${(props) => props.theme.palette.colors.white};
`;

const Title = styled.h3`
  display: block;
  width: 100%;
  margin: 0;
  font-size: ${rem(20)};

  ${breakpoint('large')} {
    font-size: ${rem(22)};
  }
`;

const Details = styled.h4`
  display: block;
  width: 100%;
  margin: 0;
  font-size: ${rem(15)};

  ${breakpoint('large')} {
    font-size: ${rem(16)};
  }
`;

const Position = styled.span`
  display: block;
  color: ${(props) => props.theme.palette.colors.primary};
  margin-top: 0.2rem;
  margin-bottom: 0.65rem;
`;

const Phone = styled.a`
  display: block;
  margin-bottom: 0.25rem;
  color: ${(props) => props.theme.palette.colors.white};
`;

const Email = styled.a`
  display: block;
  margin-bottom: 0.25rem;
  color: ${(props) => props.theme.palette.colors.white};
`;

function StaffItem({ data }) {
  return (
    <Root>
      <Inner to={data}>
        {data.image && (
          <Thumbnail>
            <Image
              fluid={data.imageSharp?.childImageSharp?.fluid}
              fallback={data.image}
              alt={RichText.asText(data.name)}
            />
          </Thumbnail>
        )}
        <Content>
          <Title>{RichText.asText(data.name)}</Title>
          <Details>
            {data.position && <Position>{data.position}</Position>}
            {data.phone && (
              <Phone href={`tel:${data.phone}`}>{data.phone}</Phone>
            )}
            {data.email && (
              <Email href={`mailto:${data.email}`}>{data.email}</Email>
            )}
          </Details>
        </Content>
      </Inner>
    </Root>
  );
}

StaffItem.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default StaffItem;
